import React from "react";
import { ReactSVG } from "react-svg";
import "./styles.scss";

const ContactInfo = ({ contactWebFullName, contactPhoneNumber, contactHours }) => {
  const data = {
    title: `Contact ${contactWebFullName}`,
    subtitle: `If you’re having difficulties using our website or have any questions about our service, we’re on hand to
    help.`,
    phone: contactPhoneNumber,
    hours: `Contact hours: ${contactHours}`,
  };

  return (
    <div className="ContactInfo">
      <div className="ContactInfo__left">
        <ReactSVG
          className="ContactInfo__image"
          src={`/svg/contact.svg`}
        />
        <div>
          <div className="ContactInfo__title">{data.title}</div>
          <p className="ContactInfo__subtitle">{data.subtitle}</p>
        </div>
      </div>
      <div className="ContactInfo__right">
        <div className="ContactInfo__phone">{data.phone}</div>
        <p className="ContactInfo__hours">{data.hours}</p>
      </div>
    </div>
  );
};

export default ContactInfo;
