import React from "react";
import { TypeSelector } from "../../../services/componentSwitcher/TypeSelector";
import { TypeSelectorValidate } from "../../../services/componentSwitcher/TypeSelectorValidate";
import { refHandler } from "../../../services/componentSwitcher/ComponentSwitcher";
import { groupFromField } from "../../../services/componentSwitcher/getGroup";
import FormComponents from "../FormComponents";
import { PostContactFormHandler } from "../../../services/api/PostContactForm/PostContactFormHandler";
import Wrapper from "../../Layout/Wrapper/wrapper";
import Button from "../../atoms/Button";
import check from "../../../images/icons/done_24pxgreen.svg";
import { scrollBehaviorTo, scrollY } from "../../utils";
import "./contactForm.scss";
import sanitizeHTML from "../../utils/sanitizeHtml";
import { getCookie } from "../../../components/utils/cookies";

export default class ContactForm extends React.Component {
  state = {
    form: this.props.json.fields,
    structure: this.props.json.structure,
    formState: "start",
  };

  handleChange = (e) => {
    const target = e.target;
    let value = target.value; //Gathering the value of the event
    const name = target.name; //Gathering the target of the event
    const tempForm = { ...this.state.form };
    const field = tempForm[name];

    field.value = TypeSelector(field.component, value, target.checked); //This function sets the value of the field in the state, it's the one that's responsible for checking the type and set the value accordingly
    field.validate = true;

    this.setState({ form: tempForm });
  };

  fieldValidator = (e) => {
    const tempForm = { ...this.state.form };
    const structureForm = { ...this.state.structure };
    let validated = true;
    const field = e.target.name;

    if (
      refHandler(groupFromField(field, structureForm), tempForm) &&
      refHandler(tempForm[field], tempForm)
    ) {
      if (tempForm[field].required || tempForm[field].type === "text") {
        if (!TypeSelectorValidate(tempForm[field], tempForm[field].value)) {
          tempForm[field].validate = false;
          if (validated) {
            validated = false;
          }
        } else {
          tempForm[field].validField = true;
          tempForm[field].validate = true;
        }
      }
      if (tempForm[field].value === "0" && tempForm[field].type === "checkbox") {
        if (validated) {
          validated = false;
        }
        tempForm[field].validField = false;
        tempForm[field].validate = false;
      }
      if (tempForm[field].name === "email") {
        const rgxEmail = /^(?=.{1,33}$)\w+([-+._']\w+)*@\w+([-._]\w+)*\.\w+([-.]\w+)*$/;
        let fieldToValidate = tempForm[field];
        let isValid = rgxEmail.test(fieldToValidate.value);

        tempForm[field].validField = isValid;
        tempForm[field].validate = isValid;
      }
    }
    this.setState({ form: tempForm });

    return validated;
  };

  validateForm = () => {
    const tempForm = { ...this.state.form };
    let validated = true;
    let refErrorElement = "";

    const fieldValidator = this.fieldValidator;
    Object.keys(tempForm).forEach(function (field) {
      const eventMock = {
        target: {
          name: field,
          value: tempForm[field].value,
        },
      };

      if (!fieldValidator(eventMock)) {
        validated = false;
        refErrorElement = field;
      }
    });

    //CUSTOM VALIDATIONS FOR THE PROJECT
    if (refErrorElement !== "") this.scrollToRef(refErrorElement);

    this.setState({ form: tempForm });

    return validated;
  };

  scrollToRef = (ref) => {
    scrollBehaviorTo(document.getElementById(ref).getBoundingClientRect().top + scrollY() - 110);
  };

  onSubmit = () => {
    if (!this.validateForm()) {
      return false;
    }

    let data = Object.values(this.state.form).reduce((obj, field) => {
      if (
        field.value !== "" &&
        refHandler(field, this.state.form) &&
        refHandler(groupFromField(field.name, this.state.structure), this.state.form)
      ) {
        obj[field.name] = field.value;
      }
      return obj;
    }, {});

    const contactFormHandler = new PostContactFormHandler({});

    contactFormHandler
      .customAction(data)
      .then((res) => {
        this.setState({ formState: "successForm" });
        window.scroll(0, 0);
      })
      .catch((error) => {
        let formError = this.state.form;
        formError.config.error = true;
        this.setState({
          loading: false,
          form: formError,
        });
      });
  };

  startForm = () => {
    this.setState({ formState: "start" });
    const tempForm = { ...this.state.form };

    Object.keys(tempForm).forEach(function (field) {
      tempForm[field].value = "";
      tempForm[field].validField = false;
    });
  };

  render() {
    const contactInfoSvgs = this.props.contactShowInfo;
    const siteInfo = getCookie("site_info");
    const showSpecialNMIInfo =
      siteInfo.showSpecialNMIInfo !== null ? siteInfo.showSpecialNMIInfo : false;

    const start = (
      <div className="contact__wrapper">
        <form
          onSubmit={this.onSubmit}
          className="doc">
          <div className="doc-container">
            {this.state.structure.steps.map((step) => (
              <div
                className="doc-step"
                key={step.name}>
                {step.groups.map((group) => (
                  <div key={group.name}>
                    {refHandler(group, this.state.form) && (
                      <>
                        {showSpecialNMIInfo && (
                          <div className="contact__info">
                            <span
                              dangerouslySetInnerHTML={{
                                __html: sanitizeHTML(contactInfoSvgs.footer_name_company_1),
                              }}
                            />
                            <span
                              dangerouslySetInnerHTML={{
                                __html: sanitizeHTML(contactInfoSvgs.footer_direction),
                              }}
                            />
                            <span
                              dangerouslySetInnerHTML={{
                                __html: sanitizeHTML("+1 (786) 2091728"),
                              }}
                            />
                          </div>
                        )}
                        <p>
                          Use the form below to submit a support request. A customer service agent
                          will be in touch with you as soon as posible. If submitted after office
                          hours, we will respond you on the next business day.
                        </p>
                        <div className="doc-group m-contact-doc u-flex u-flex--wrap u-flex--justify-between">
                          {group.fields.map((item, index) => {
                            const field = this.state.form[item];
                            const FieldComponent = FormComponents[field.component];
                            return (
                              <React.Fragment key={`doc-group-${index}`}>
                                {refHandler(field, this.state.form) && (
                                  <FieldComponent
                                    onBlur={this.fieldValidator}
                                    onChange={this.handleChange}
                                    {...field}
                                    key={`${field.name.toString()}-${index}`}
                                  />
                                )}
                              </React.Fragment>
                            );
                          })}
                        </div>
                      </>
                    )}
                  </div>
                ))}
              </div>
            ))}
            <div className="contact__submit">
              <Button
                primary
                data-qa="submit-form"
                label="Send Message"
                onClick={this.onSubmit}
              />
            </div>
          </div>
        </form>
        <div className="contact__email e-text-helper">Email address: info@{siteInfo.domain}.</div>
      </div>
    );
    const successForm = (
      <Wrapper>
        <div className="contact__success">
          <img
            src={check}
            alt={`Your message have been sent`}
          />
          <p className={"successMessage"}>Your message has been sent</p>
          <Button
            primary
            data-qa="submit-form"
            label="Send new message"
            onClick={this.startForm}
          />
        </div>
      </Wrapper>
    );
    const FormPhase = {
      start: start,
      successForm: successForm,
    };

    return <div>{FormPhase[this.state.formState]}</div>;
  }
}
