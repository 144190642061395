import React, { useContext } from "react";
import SiteDataContext from "../context/SiteDataContext";
import { Layout } from "../components/Layout/layout";
import Seo from "../components/utils/SEO";
import { useStaticQuery, graphql } from "gatsby";
import { getCookie } from "../components/utils/cookies";
import { Helmet } from "react-helmet";
import Wrapper from "../components/Layout/Wrapper/wrapper";
import ContactForm from "../components/Form/ContactForm";
import Form from "../data/contact";
import { Title } from "../components/atoms/Title/title";
import ContactInfo from "../components/Form/ContactInfo";

const Contact = () => {
  const data = useStaticQuery(pageQuery);
  const contactData = data.contentfulContact;
  const { siteData } = useContext(SiteDataContext);
  const { web_full_name, footer_name_company_1, footer_direction, site_phone, contact_hours } =
    siteData ? siteData : "";

  const contactShowInfo = {
    web_full_name: web_full_name,
    footer_name_company_1: footer_name_company_1,
    footer_direction: footer_direction,
    site_phone: site_phone,
  };

  const contactWebFullName = contactShowInfo.web_full_name;

  const siteInfo = getCookie("site_info");
  const showInfoCard = siteInfo.hasPhone;
  const contactPhoneNumber = siteInfo.companyPhoneNumber;

  return (
    <Layout hideMenuButton={false}>
      <Seo title="Contact Us" />
      <Helmet>
        <title>{contactData.title}</title>
        <meta
          name="description"
          content=""
        />
      </Helmet>
      <Wrapper paddingTop>
        <Title
          hero
          tag="h1"
          content={contactData.title}
        />
      </Wrapper>
      <Wrapper>
        <ContactForm
          contactShowInfo={contactShowInfo}
          json={Form}
        />
        {showInfoCard && (
          <ContactInfo
            contactPhoneNumber={contactPhoneNumber}
            contactWebFullName={contactWebFullName}
            contactHours={contact_hours}
          />
        )}
      </Wrapper>
    </Layout>
  );
};

export default Contact;

export const pageQuery = graphql`
  query {
    contentfulContact {
      slug
      title
    }
  }
`;
